.mobileNav .topBar{display: flex;align-items: center;justify-content: space-between;width: 100%;position:relative;}
.mobileNav .searchBox{position:relative; width:100%;}
.mobileNav .searchIcon{position:absolute; left:10px; top:27px;    color: #999;}
.mobileNav .searchBox .form-control{padding-left:2rem;    margin-top: 1rem;}
.mobileNav .topBar .navbar-toggler{padding:0px; border:none;}
.mobileNav .shoppingCart{color:#fff; font-size: 1.25rem;}
.mobileNav #responsive-navbar-nav{position: absolute;top: 34px;z-index: 9;width: 100%;}
.mobileNav{display:none;}
@media (max-width: 767px){
	.desktopNav{display:none;}
	.mobileNav{display:block;}
}