.card {
    max-width: 100% !important;
    min-height: 382px;
    overflow: hidden;
}
.card-img-top{
    height: 158px;
    max-width: 100%;
    object-fit: cover;
}

.card .card-text{display:none;}
.card-title {    margin-bottom: .5rem !important;font-size: 14px !important;text-transform: capitalize;}
.card .input-group-text {padding: .2rem .5rem;}
.card  .card-body{padding:1rem 1rem 0rem!important;}
.card .productName{height: 36px;overflow: hidden;}
#collasible-nav-dropdown{color:#fff;}
.priceTable td, .priceTable th{padding:0px !important; font-size:12px;text-align: center;}
.priceTable td:last-child, .priceTable th:last-child{text-align:right;}
.priceTable td:first-child, .priceTable th:first-child{text-align:left;}
@media (max-width: 767px){
	.card .productName, .card{height: auto;}
}